<template>
  <section id="listaCompensacao">
    <div class="cabecalho d-flex justify-content-between">
      <Breadcrumb></Breadcrumb>

      <div class="cabecalho-inputs d-flex row">
        <div
          id="filter-input-container"
          class="cabecalho-tools d-flex justify-content-between"
        >
          <input
            class="cabecalho-input"
            id="filter-input"
            type="text"
            placeholder="Código de Oportunidade, Empresa, CNPJ..."
            v-model="searchQuery"
          />
          <button class="btn" type="submit" variant="dark">
            <Icon :iconName="'search'" class="nav-icon" />
          </button>
        </div>
        <!-- <button class="cabecalho-tools cabecalho-btn" @click="btnModalTeses()">
            Enviar Arquivos 
            <Icon :iconName='"upload"' class="nav-icon"/>
          </button> -->
      </div>
    </div>

    <div class="jumbotron">
      <b-overlay
        :show="loadingSpinner"
        rounded="sm"
        opacity="0.6"
        spinner-small
        spinner-variant="secondary"
      >
        <div class="p-1">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Código da Oportunidade</th>
                <th scope="col">Empresa</th>
                <th scope="col">CNPJ</th>
                <th scope="col" @click="sort('dataCriacao')">
                  Data da Criação
                </th>
                <th scope="col">Saldo</th>
                <th scope="col">Quantidade de guias</th>
                <th scope="col">Observação</th>
                <th scope="col">Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="cursor: pointer"
                v-for="(compensacao, index) in filteredListaCompensacao"
                :key="index"
                @click="rowClick(compensacao)"
              >
                <td scope="row">#{{ compensacao.ncodOp }}</td>
                <td>
                  {{ compensacao.nomeCliente.toUpperCase() }}
                  <span
                    v-if="
                      compensacao.diasVencimento > 0 &&
                      compensacao.diasVencimento <= 10
                    "
                    style="display: block; color: red"
                  >
                    Faltam {{ compensacao.diasVencimento }} dias para vencer!
                  </span>
                  <span
                    v-else-if="compensacao.diasVencimento < 0"
                    style="display: block; color: red"
                  >
                    VENCIDO A MAIS DE
                    {{ Math.abs(compensacao.diasVencimento) }} DIAS!.
                  </span>
                  <span
                    v-else-if="compensacao.diasVencimento == 0"
                    style="display: block; color: red"
                  >
                    ATENÇÂO! VENCE HOJE.
                  </span>
                </td>
                <td>{{ formatCnpj(compensacao.cnpj) }}</td>
                <td>{{ formatDatePTBR(compensacao.dataCriacao) }}</td>
                <td>{{ formatMoney(compensacao.saldo) }}</td>
                <td>
                  <span v-if="compensacao.qntGuias === 0">N/A</span>
                  <span v-else>{{ compensacao.qntGuias }}</span>
                </td>
                <td>
                  <div>
                    <button
                      class="btn btn-sm btn-outline-green"
                      @click="
                        carregarObservacao(compensacao.ncodOp, compensacao.cnpj)
                      "
                    >
                      Observação
                    </button>
                  </div>
                </td>
                <td>
                  <div>
                    <button
                      class="btn btn-sm btn-outline-blue"
                      @click="
                        carregarGuias(compensacao.ncodOp, compensacao.cnpj)
                      "
                    >
                      Ver Guias
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>

      <!-- Modal preencher observacao -->
      <b-modal size="sm" id="modal-observacao" ref="modal-observacao">
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <textarea
              id="observacao"
              v-model="txtObservacao"
              class="form-control"
              rows="4"
            ></textarea>
          </div>
          <!-- <button type="submit" class="btn btn-primary">Salvar</button> -->
          <button type="submit" class="btn btn-primary">
            {{ txtObservacao ? "Salvar" : "Editar" }}
          </button>
        </form>
        <template #modal-footer>
          <b-button variant="btn btn-outline-danger" @click="fecharModal()">
            Cancelar
          </b-button>
        </template>
      </b-modal>

      <div class="pagination-nav justify-content-center row my-1">
        <button @click="pagNavigation('first')">
          <Icon :iconName="'chevron-double-left'" class="nav-icon" />
        </button>
        <button @click="pagNavigation('previous')">
          <Icon :iconName="'chevron-left'" class="nav-icon" />
        </button>
        <button id="pag-current-page">{{ currentPage }}</button>
        <button @click="pagNavigation('next')">
          <Icon :iconName="'chevron-right'" class="nav-icon" />
        </button>
        <button @click="pagNavigation('last')">
          <Icon :iconName="'chevron-double-right'" class="nav-icon" />
        </button>
      </div>
    </div>

    <!-- modal preencher compensação -->
    <!-- <b-modal
      :title="compensacao"
      size="sm"
      id="modal-compensacao"
      ref="modal-compensacao"
      scrollable
    >
      <div>
        <div class="form-group">
          <label style="display: block">Competencia Compensada: </label>
          <input v-model="competenciaCompensada" type="month" />
        </div>

        <div class="form-group">
          <label>Selecione os periodos do crédito </label>
          <select v-model="periodoSelecionado" @change="additemToList">
            <option
              v-for="(item, index) in periodosCompensar"
              :key="index"
              :value="item.periodoApuracao"
              :class="{ selected: selectedSet.has(item.periodoApuracao) }"
              class="select-space"
            >
              {{ item.periodoApuracao }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label
            >Valor original dos periodos :
            {{ formatMoney(somaValorOriginalPeriodos) }}</label
          >
        </div>

        <div class="form-group">
          <label style="display: block">Valor original: </label>
          <input v-model="creditoOriginal" readonly />
        </div>

        <div class="form-group">
          <label style="display: block">Valor compensado: </label>
          <input type="number" v-model="valorCompensado" />
        </div>

        <div class="form-group">
          <label style="display: block">Selic: </label>
          <input v-model="selic" />
        </div>

        <div class="form-group">
          <label style="display: block">Processo: </label>
          <input v-model="processo" />
        </div>

        <div class="form-group">
          <label style="display: block">Guia: </label>
          <input v-model="guia" type="month" />
        </div>
      </div>

      <template #modal-footer>
        <b-button
          variant="btn btn-outline-info"
          @click="processarCompensacao()"
        >
          Enviar
        </b-button>
        <b-button variant="btn btn-outline-danger" @click="fecharModal()">
          Cancelar
        </b-button>
      </template>
    </b-modal> -->
  </section>
</template>

<script>
const API_URL = process.env.VUE_APP_URL_API;
import Breadcrumb from "@/Components/Breadcrumb/Breadcrumb.vue";
import Icon from "@/Components/Icon/icon.vue";
import compensacaoService from "@/services/teses/compensacao.service";
import helperService from "../../../services/helper.service";
import { router } from "@/router";

export default {
  name: "ListaCompensacao",
  components: {
    Breadcrumb,
    Icon,
  },

  data() {
    return {
      loadingSpinner: false,
      listaCompensacao: [],
      currentSort: "dataImportacao",
      currentSortDir: "desc",
      small: true,
      searchQuery: "",
      currentPage: 1,
      pageSize: 15,
      returnTeses: false,
      compensacao: "",
      periodosCompensar: [],
      periodoSelecionado: "",
      periodosSelecionados: [],
      selectedSet: new Set(),
      creditoOriginal: "",
      valorCompensado: "",
      selic: "",
      processo: "",
      guia: "",
      competenciaCompensada: "",
      objCompensacao: "",
      ncodOp: "",
      cnpj: "",
      somaValorOriginalPeriodos: 0,
      txtObservacao: "",
    };
  },

  mounted() {
    this.getListaCompensacao();
  },

  methods: {
    // Returns a list of companies that already sent its manad file
    // Have the following rows: NCodOp, Nome, CNPJ, Data de Registr
    async getListaCompensacao() {
      this.loadingSpinner = true;
      await compensacaoService
        .getListaCompensacao()
        .then((response) => {
          console.log("response==>", response);
          if (response.status == 200) {
            this.listaCompensacao = response.data.dados;
          }
        })
        .catch((error) => {
          this.$toast.open({ message: error, type: "error", duration: 2000 });
          this.loadingSpinner = false;
        });

      this.loadingSpinner = false;
    },

    rowClick(file) {
      console.log("file", file);
      this.$router.push(
        `/calculo/compensacao/${file.ncodOp}/${file.cnpj}/${0}`
      );
    },

    // async gerarCompensacao(compensacao) {
    //   event.stopPropagation();
    //   console.log("compensacao==>", compensacao);
    //   this.objCompensacao = compensacao;
    //   await compensacaoService
    //     .getPeriodosCompensacao(compensacao.ncodOp, compensacao.cnpj)
    //     .then((response) => {
    //       if (response.status == 200) {
    //         this.periodosCompensar = response.data.dados;
    //         this.ncodOp = compensacao.ncodOp;
    //         this.cnpj = compensacao.cnpj;
    //       }
    //     });
    //   let valorAux = this.formatMoney(compensacao.saldo);
    //   this.creditoOriginal = valorAux;
    //   this.$refs["modal-compensacao"].show();
    // },

    additemToList() {
      if (this.periodoSelecionado) {
        console.log("this.periodoSelecionado", this.periodoSelecionado);
        if (this.selectedSet.has(this.periodoSelecionado)) {
          const index = this.periodosSelecionados.indexOf(
            this.periodoSelecionado
          );
          if (index !== -1) {
            this.periodosSelecionados.splice(index, 1);
            const periodoRemovido = this.periodosCompensar.find(
              (item) => item.periodoApuracao === this.periodoSelecionado
            );
            if (periodoRemovido) {
              this.somaValorOriginalPeriodos -= periodoRemovido.valorOriginal;
            }
          }
          this.selectedSet.delete(this.periodoSelecionado);
        } else {
          this.selectedSet.add(this.periodoSelecionado);
          this.periodosSelecionados.push(this.periodoSelecionado);
          const periodoAdicionado = this.periodosCompensar.find(
            (item) => item.periodoApuracao === this.periodoSelecionado
          );
          if (periodoAdicionado) {
            this.somaValorOriginalPeriodos += periodoAdicionado.valorOriginal;
          }
        }
        this.periodoSelecionado = null;
        const label = this.$el.querySelector("label");
        label.textContent = this.periodosSelecionados.join(", ");
      }
    },

    async processarCompensacao() {
      const getSelic = this.selic.replace(",", ".");
      this.selic = parseFloat(getSelic);
      await compensacaoService
        .processarCompensacao(
          this.ncodOp,
          this.cnpj,
          this.competenciaCompensada,
          this.valorCompensado,
          this.creditoOriginal,
          this.selic,
          this.periodosSelecionados,
          this.processo,
          this.guia
        )
        .then((response) => {
          if (response.status == 200) {
            console.log("ok");
          }
        });
    },

    async submitForm() {
      console.log("this.txtObservacao", this.txtObservacao);

      await compensacaoService
        .setObservacao(this.ncodOp, this.cnpj, this.txtObservacao)
        .then((response) => {
          if (response.status == 200) {
            this.fecharModal();
          }
        })
        .catch((error) => {
          console.log("error:", error);
          this.fecharModal();
          this.$toast.open({ message: error, type: "error", duration: 2000 });
        });
      this.fecharModal();
    },

    fecharModal() {
      //   (this.competenciaCompensada = ""),
      //     (this.periodosSelecionados = []),
      //     (this.valorCompensado = ""),
      //     (this.selic = ""),
      //     (this.processo = ""),
      //     (this.guia = ""),
      //     (this.somaValorOriginalPeriodos = ""),
      this.$refs["modal-observacao"].hide();
    },

    async carregarObservacao(ncodOp, cnpj) {
      event.stopPropagation();
      this.ncodOp = ncodOp;
      this.cnpj = cnpj;
      this.loadingSpinner = true;
      await compensacaoService
        .getObservacao(ncodOp, cnpj)
        .then((response) => {
          console.log("response==>", response);
          if (response.status == 200) {
            this.txtObservacao = response.data.dados;
          }
        })
        .catch((error) => {
          this.$toast.open({ message: error, type: "error", duration: 2000 });
          this.loadingSpinner = false;
          this.$refs["modal-observacao"].hide();
        });
      this.loadingSpinner = false;
      this.$refs["modal-observacao"].show();
    },

    carregarGuias(ncodOp, cnpj) {
      event.stopPropagation();
      this.$router.push(`/teses/compensacao/listaguias/${ncodOp}/${cnpj}/`);
    },

    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    // fetchCalculoInss(nCodOp) {
    //   this.$router.push(`/manadRubricaPesquisa/${nCodOp}/`);
    // },

    formatDatePTBR(date) {
      const formatDate = date.split("T")[0].split("-").reverse().join("/");
      return formatDate;
    },

    formatCnpj(cnpj) {
      const formatedCnpj = cnpj.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
      return formatedCnpj;
    },

    pagNavigation(page) {
      switch (page) {
        case "first":
          this.currentPage = 1;
          break;
        case "previous":
          if (this.currentPage > 1) this.currentPage--;
          break;
        case "next":
          if (this.currentPage * this.pageSize < this.listaCompensacao.length)
            this.currentPage++;
          break;
        case "last":
          this.currentPage = Math.ceil(
            this.listaCompensacao.length / this.pageSize
          );
          break;
        default:
          try {
            if (
              page <= this.listaCompensacao.length / this.pageSize &&
              page >= 1
            )
              this.currentPage = page;
          } catch (error) {
            console.log("Cannot go to the page " + page);
          }
          break;
      }
    },

    teses(params) {
      this.returnTeses = params;
    },

    formatMoney(number) {
      return helperService.formatMoneyPtBR(number);
    },
  },

  computed: {
    sortedListaCompensacao: function () {
      return this.listaCompensacao.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },

    filteredListaCompensacao: function () {
      let listaFiltrada = this.sortedListaCompensacao.filter((compensacoes) => {
        for (var compensacao in compensacoes) {
          if (
            String(compensacoes[compensacao]).indexOf(
              this.searchQuery.toUpperCase()
            ) !== -1
          ) {
            return true;
          }
        }
        return false;
      });

      this.totalPages = Math.ceil(listaFiltrada.length / this.pageSize);
      return listaFiltrada.filter((row, index) => {
        let start = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;
        if (index >= start && index < end) return true;
      });
    },
  },
};
</script>

<style scoped>
.selected {
  background-color: lightgray;
}
.form-group {
  margin-bottom: 5px; /* Adicione o espaçamento desejado aqui */
  margin-left: 10px;
  margin-right: 10px;
}

.select-space {
  margin-left: 10px;
  margin-right: 10px; /* Adicione o espaçamento desejado entre a label e o select */
}

.table th,
.table td {
  text-align: center;
  font-size: 14px;
}

.table {
  font-size: 12px; /* Defina o tamanho da fonte desejado */
}
</style>
