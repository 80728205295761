import axios from "axios";
const API_URL = process.env.VUE_APP_URL_API;

class CompensacaoService {

  async getListaCompensacao() {
    return await axios.get(`${API_URL}/CreditoInss/getListaCompensacao/`);   
  }

  async getPeriodosCompensacao(nCodOp, cnpj, data) {
    return await axios.get(`${API_URL}/CreditoInss/getPeriodosCompensacao/`,{
      params:{
        NCodOp: nCodOp,
        Cnpj: cnpj,
        DtGuia: data
      }
    }); 
  }

  async getListaGuias(nCodOp, cnpj) {
    return await axios.get(`${API_URL}/CreditoInss/getListaGuias/`,{
      params:{
        NCodOp: nCodOp,
        Cnpj: cnpj,
      }
    }); 
  }



  async getCompensacoes(nCodOp, cnpj, dtGuia) {
    return await axios.get(`${API_URL}/CreditoInss/getCompensacoes/`,{
      params:{
        NCodOp: nCodOp,
        Cnpj: cnpj,
        DtGuia: dtGuia
      }
    }); 
  }

  async getObservacao(nCodOp, cnpj) {
    return await axios.get(`${API_URL}/CreditoInss/getObservacao/`,{
      params:{
        NCodOp: nCodOp,
        Cnpj: cnpj,
      }
    }); 
  }

  async processarCompensacao(ncodOp, cnpj, competenciaCompensada, valorCompensado, creditoOriginal, valorOriginal, selic, periodosSelecionados,
    processo, guia, observacao){
    let params = {
        NcodOp : ncodOp,
        Cnpj : cnpj,
        Competencia_Compensada : competenciaCompensada,
        ValorOriginal : creditoOriginal,
        Valor_Compensado: valorCompensado,
        ListPeriodosCredito : periodosSelecionados,
        ValorOriginal: valorOriginal,
        Selic: selic,
        Processo: processo,
        Guia: guia,
        Observacao: observacao,
    };
    console.log("params",params);
    return await axios.post(`${API_URL}/CreditoInss/processarCompensacao`, params);
  }

  async setObservacao(nCodOp, cnpj, observacao){
    console.log("nCodOp", nCodOp);
    let params = {
      NCodOp: nCodOp,
      Cnpj: cnpj,
      Observacao: observacao
    };
    return axios.post(`${API_URL}/CreditoInss/setObservacao/`,params);
  }
 
}

export default new CompensacaoService();