<template lang="">
    <div class="cabecalho-rota ">
        <ul class="d-flex flow-row text-decoration-none">
            <li>
                <button @click="routePusher('home')">
                    Portal TJ
                </button>>
            </li>

            <li v-for="(path, index) in routeList" :key="index"> 
                <button @click="routePusher(index)">
                    {{path}}
                </button> >
            </li>

            <li>
               <button class="current-page">{{this.lastPath}}</button>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "Breadcrumb",
    data(){
        return{
            routeList: [],
            lastPath: '',
            lastRoute: '',
            newRoute: '',
            pathList: '',
            ncodop: '',
        };
    },

    mounted() {
        this.getRouteList()
    },

    methods: {
        async getRouteList(){
            this.routeList = this.$route.name.split('/').filter((path) => path !== ""),
            this.pathList = this.$route.path.split('/').filter((path) => path !== ""),
            this.ncodop = this.pathList.pop();
            this.lastRoute = this.routeList.pop();              
            this.lastPath = this.lastRoute === "_nCodOp" ? this.ncodop : this.lastRoute;
        },
        
        routeBuilder(pathIndex){
            this.newRoute = "/";
            for (let index = 0; index <= pathIndex; index++) {
                this.newRoute += this.routeList[index] + "/";
            }
            return this.newRoute;
        },

        routePusher(pathIndex){
            this.newRoute = this.routeBuilder(pathIndex);
            this.$router.push(`${this.newRoute}`)
        },
    },
}
</script>

<style src="./BreadcrumbStyles.scss" lang="scss">